@import "../Vars.scss";

.card-header {
    padding: $CardPadding;
    border-bottom: 1px solid $ColorGrey;

    .title {
        font-size: $FontSizeTitle;
    }

    .subtitle {
        font-size: $FontSizeSubtitle;
        color: $ColorTextGrey;
    }
}