@import "../Vars.scss";

footer {
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #eee;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    color: #717171;
    font-size: $FontSizeSubtitle;
}