@import "../Vars.scss";

.formlist {
    display: flex;

    .no-forms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 100%;
        text-align: center;
        border-top: 1px solid var(--color-border-grey);
    }

    .formlist-item {
        display: flex;
        width: 100%;
        padding: 15px 20px;
        border-bottom: 1px solid var(--color-border-grey);
        cursor: pointer;
        transition: background-color .08s;
    
        text-decoration: none;
        color: $ColorTextBlack;
    
        &:first-child {
            border-top: 1px solid var(--color-border-grey);
        }
    
        &:hover {
            background-color: var(--color-hover-grey);
        }
    }
    
    .formlist-column {
        flex: 1;
        padding: 0 5px;
    
        .column-header {
            font-size: var(--font-size-subtitle);
            color: var(--color-text-grey);
            margin-bottom: 5px;
        }
    
        .column-value {
            font-weight: 500;
        }
    }
}

