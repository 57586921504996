@import "../Vars.scss";

.history-list {

    .no-history {
        text-align: center;
        padding: 20px;
        color: $ColorTextGrey;
        font-size: $FontSizeSubtitle;
    }

}

.history-item {
    display: grid;
    grid-template-areas: 
        "outcome username"
        "stage stage"
        "comments comments"
        "date date"
    ;
    gap: 12px;
    padding: 20px;
    font-size: 14px;
    border-bottom: 1px solid $ColorGrey;

    &:last-child {
        border-bottom: none;
    }

    .history-outcome {
        grid-area: outcome;

        > span { 
            display: inline-block;
            padding: 5px;
            border-radius: 10px;
            background-color: $ColorStatusGrey; 
            padding: 5px 10px;
        }

        &.completed > span { background-color: $ColorStatusGreen; }
        &.approved > span { background-color: $ColorStatusBlue; }
        &.clarification > span { background-color: $ColorStatusYellow; }
        &.rejected > span { background-color: $ColorStatusRed; }
    }

    .history-user {
        grid-area: username;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .history-stage {
        grid-area: stage;
        font-weight: bold;
    }

    .history-comments {
        grid-area: comments;
        line-height: 20px;
    }
    
    .history-date {
        grid-area: date;
        color: $ColorTextGrey;
    }
}

