
$ColorBackground: #F4F9FD;
$ColorTextBlack: #333;
$ColorTextGrey: #7D7D7D;

$ColorGrey: #ddd;
$ColorHoverGrey: #eee;

$FontSizeSubtitle: 14px;
$FontSizeText: 16px;
$FontSizeTitle: 18px;

$CardPadding: 20px;

$PageWidth: 1400px;

$BoxShadow1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); 

$ColorStatusYellow: #FFEBC0;
$ColorStatusGreen: #CAf0CC;
$ColorStatusBlue: #D4E7F6;
$ColorStatusGrey: #F4F4F4;
$ColorStatusRed: #FDE7E9;