@import "./Vars.scss";

* {
    box-sizing: border-box;
}

:root {
    --font-size-body: 16px;
    --font-size-subtitle: 14px;

    --color-text-black: #333;
    --color-text-grey: #7D7D7D;

    --color-border-grey: #ddd;

    --color-hover-grey: #f9f9f9;
}

body {
    margin: 0;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: $ColorBackground;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

hr { 
    border: none;
    border-bottom: 1px solid $ColorGrey;
    margin: 15px 0;
}

p {
    margin: 8px 0;
}

main {
    display: grid;
    padding: 20px;
    grid-template-columns: 3fr 1fr;
    row-gap: 20px;
    column-gap: 20px;

    width: 100%;
    max-width: $PageWidth;
    margin: 0 auto;
}

.vpi-display-field {

    .ms-Label {
        color: #323130;
    }

    .ms-TextField-field {
        color: #777
    }
}

h3 {
    margin: 20px 0 0 0;
}

ol {
    li::before {
        content: '';
        width: 10px;
        display: inline-block;
    }
}