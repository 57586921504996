.print-only { display: none; }

.form-header {
    display: none;

    .form-logo {
        flex: 1;
        img { height: 80px; }
    }

    .form-title {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;

        text-transform: uppercase;
        color: #103A62;
        font-weight: bold;


        .form-title-text {
            font-size: 22px;
            margin: 2px 0;
        }

        .form-title-subtext {
            font-size: 18px;
            margin: 2px 0;
        }
    }
}

@media print {

    .App {
        > header { display: none; }
        .page-header { display: none; }
    }
    
    main {
        display: block;
    
        .card:nth-child(2), .card:nth-child(4) { display: none; }
    }
    
    .card {
        box-shadow: none;
    }

    .card-header {
        display: none;
    }

    .form-header {
        display: flex;
    }

    form {
        gap: 8px !important;

        > div {
            page-break-inside: avoid;

        }
    }
    
    .ms-TextField-fieldGroup,
    .ms-ComboBox::after,
    .ms-Dropdown-title {
        border: 1px solid #eee;
    }
    
    footer { display: none; }
    .hide-print { display: none; }
    .print-only { display: block; }

    .certification-form {
        page-break-before: always;
        page-break-inside: avoid;
    }
}

