@import "../Vars.scss";

header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    box-shadow: $BoxShadow1;
    

    .header-logo {
        flex: 0 0 auto;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $ColorTextBlack;
    }

    .header-profile {
        flex: 0 0 auto;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $ColorHoverGrey;
        }

        .user-details { 
            margin-left: 10px;
        }

        .user-account {
            font-size: 12px;
            color: var(--color-text-grey);
        }
    }

    .logo {
        height: 100%;
        margin-right: 10px;

        img {
            height: 100%;
        }
    }

    .ms-Persona-details {
        display: none;
    }
}
